.header {
    background: black;    
}

.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 329px;
}

.menu-content {
    max-width: 1280px;
    margin: auto;
    padding: 10px;
    justify-content:center;
 }


.menu {
    display: flex;
    text-align: center;
}

.menu-item {
    color: white;
    font-family: 'Segoe UI';
    font-size: 1.15em;
    font-weight: 400;
    margin: 10px;
    text-decoration: none;

    
  
}