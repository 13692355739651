
.footer {
    width: 100%;
    background: black;
    color: white;
    text-align: end;
  
}

.footer-text {
    margin: 10px;
    font-family: 'Segoe UI';
    font-size: 1.25em;
    font-weight: 200;
    text-decoration: none;
    padding:5px;
}
