.body {
    //background: #f9f9f9;
    
}

.body-content {
    max-width: 1280px;
    margin: auto;
    padding: 10px;
    border-radius: 25px;
    background: white;
    padding: 20px;
    margin-top: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.body-content-50pct {
    
    border-radius: 25px;
    background: white;
    padding: 10px;
    margin: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.body-header1 {
    font-family: 'Segoe UI';
    font-size: 2.5em;
    font-weight: 200;
    width: 60%;
}

.body-header3 {
    font-family: 'Segoe UI';
    font-size: 2em;
    font-weight: 200;
    
}

.body-header2 {
    font-family: 'Segoe UI';
    font-size: 2em;
    font-weight: 200;
    width: 60%;
}

.body-main {
    font-family: 'Segoe UI';
    font-size: 1.15em;
    font-weight: 200;
    margin-top: 20px;
    width: 60%;
}

.body-main2 {
    font-family: 'Segoe UI';
    font-size: 1.15em;
    font-weight: 200;
    margin-top: 20px;
  
}


.button {
    display: inline-block;
    padding: .75rem 1.25rem;
    border-radius: 10rem;
    color: black;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: .15rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.floaty-right {
    float: right;
    margin: 5px;
}
.cards {
   
    margin: auto;
}

.card {
    background: #f9f9f9;
    width: 100%;
    padding: 5px;
    margin: 10px;
    display: flex;
}

.card-header {
    font-family: 'Segoe UI';
    font-size: 1.25em;
    font-weight: 500;
    text-align: left;
    background: light-grey;
    color: black;
    width: 20%;
    padding-top: 4em;
}

.card-body {
    font-family: 'Segoe UI';
    font-size: 1em;
    font-weight: 400;
    width: 55%;
    padding: 10px;
}

.card-image {
    width: 20%;
}

.sidebyside {
    max-width: 1325px;
    margin: auto;
    display: flex;
  
}

.disclaimer {
    font-family: 'Segoe UI';
    font-size: 0.75em;
    font-weight: 100;
}

